import FacilitiesSelectController from './facilities_select_controller'

export default class extends FacilitiesSelectController {
  static targets = ['facilitiesWrapper']
  static values = {
    organizationId: String
  }

  declare readonly facilitiesWrapperTarget: HTMLInputElement
  declare organizationIdValue: string

  selectElement (): HTMLSelectElement {
    return this.facilitiesWrapperTarget.querySelector('select') as HTMLSelectElement
  }

  buildUrl (query: string): string {
    return `/facilities?page=${this.currentPage}&q[organization_id_eq]=${this.organizationIdValue}` +
      (query === '' ? '' : `&q[name_cont]=${encodeURIComponent(query)}`)
  }

  hasNoOrganizationId (): boolean {
    return this.organizationIdValue === ''
  }

  changeCompany ({ target }: { target: HTMLInputElement }): void {
    this.organizationIdValue = target.value
  }

  organizationIdValueChanged (): void {
    const isHidden: boolean = this.hasNoOrganizationId()

    this.facilitiesWrapperTarget.classList.toggle('hidden', isHidden)

    if (isHidden || this.plugin === undefined) return

    this.plugin.clear(true)
    this.plugin.clearOptions()

    this.currentPage = 1
    this.nothingMoreToLoad = false

    this.plugin.load('')
  }
}
