import { Controller } from '@hotwired/stimulus'
import TomSelect from 'tom-select/src/tom-select.popular'
import { TomInput, TomOption } from 'tom-select/src/types'
import removeButton from 'tom-select/src/plugins/remove_button/plugin'
import virtualScroll from 'tom-select/src/plugins/virtual_scroll/plugin'
import checkboxOptions from 'tom-select/src/plugins/checkbox_options/plugin'
import { escape_html } from 'tom-select/src/utils'

TomSelect.define('checkbox_options', checkboxOptions)
TomSelect.define('remove_button', removeButton)
TomSelect.define('virtual_scroll', virtualScroll)

const openFacilityIcon = '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="h-4 w-4 text-blue-500 shrink-0">' +
  '<path stroke-linecap="round" stroke-linejoin="round" d="M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"></path>' +
  '</svg>'

interface FacilityTomOption extends TomOption {
  name: string
  licenseNumber: string
  url: string
}

export default class extends Controller {
  currentPage = 1
  nothingMoreToLoad = false

  plugin: TomSelect

  connect (): void {
    this.initTomSelect()

    this.plugin.on('focus', () =>
      setTimeout(() => this.selectDropdownPosition(), 0)
    )
    this.plugin.on('type', () => this.selectDropdownPosition())
    this.plugin.on('load', () => this.selectDropdownPosition())

    this.plugin.dropdown_content.addEventListener('scroll', () => {
      const dropdown = this.plugin.dropdown_content

      if (
        dropdown.scrollTop + dropdown.clientHeight < dropdown.scrollHeight ||
        this.nothingMoreToLoad
      ) {
        return
      }

      this.loadData(this.plugin.lastQuery, (data: any[]) => {
        this.plugin.addOptions(data)
        this.plugin.refreshOptions(false)
      })
    })
  }

  initTomSelect (): void {
    this.plugin = new TomSelect(
      this.selectElement() as TomInput,
      {
        valueField: 'id',
        labelField: 'name',
        searchField: ['name', 'licenseNumber'],
        sortField: 'name',
        plugins: ['checkbox_options', 'remove_button', 'virtual_scroll'],
        hidePlaceholder: true,
        maxOptions: 99999,
        preload: true,
        render: {
          option: this.renderOption,
          item: this.renderItem
        },
        firstUrl: () => {},
        shouldLoadMore: () => !this.nothingMoreToLoad,
        onDropdownOpen: this.toggleContainerOverflow,
        onBlur: this.toggleContainerOverflow,
        load: this.loadData.bind(this),
        onType: (value: string) => {
          this.plugin.clearOptions()
          this.currentPage = 1
          this.nothingMoreToLoad = false

          if (value === '') this.plugin.load('')
        }
      }
    )
  }

  selectElement (): HTMLSelectElement {
    return this.element as HTMLSelectElement
  }

  buildUrl (query: string): string {
    if (query === '') {
      return `/facilities?page=${this.currentPage}`
    }

    return `/facilities?page=${this.currentPage}&advanced_search=true&columns[]=fields.license_number&q[name_cont]=` +
      `&q[g][0][c][0][a][0][name]=fields.license_number&q[g][0][c][0][p]=cont&q[g][0][c][0][v][0][value]=${encodeURIComponent(query)}` +
      `&q[g][0][c][1][a][0][name]=name&q[g][0][c][1][p]=cont&q[g][0][c][1][v][0][value]=${encodeURIComponent(query)}` +
      '&q[g][0][m]=or'
  }

  renderOption (data: FacilityTomOption, escape: typeof escape_html): HTMLElement {
    const optionElement = document.createElement('div')
    optionElement.innerHTML = `<div class="flex flex-col w-full"><div class="flex flex-row w-full"><p>${escape(data.name)}</p>` +
      `<a class="ml-1.5" href="${escape(data.url)}" target="_blank">${openFacilityIcon}</a></div>` +
      `<p class="text-sm text-gray-500 dark:text-gray-400">${escape(data.licenseNumber)}</p></div>`

    const facilityAnchor = optionElement.querySelector('a') as HTMLAnchorElement
    facilityAnchor.addEventListener('click', (event) => {
      event.stopPropagation()
    })

    return optionElement
  }

  renderItem (data: FacilityTomOption, escape: typeof escape_html): string {
    return `<div><p>${escape(data.name)}</p></div>`
  }

  loadData (query: string, callback: (data: any[]) => void): void {
    if (query === null) return

    const oldScrollTop = this.plugin.dropdown_content.scrollTop

    fetch(this.buildUrl(query), {
      headers: {
        Accept: 'application/json'
      }
    })
      .then(async (response) => await response.json())
      .then((data) => {
        callback(data)

        if (data.length > 0) this.currentPage++
        this.nothingMoreToLoad = data.length === 0

        requestAnimationFrame(() => {
          this.plugin.dropdown_content.scrollTop = oldScrollTop
        })
      })
      .finally(() => this.plugin.trigger('no_results'))
  }

  toggleContainerOverflow (): void {
    document
      .querySelectorAll('.overflow-y-auto, .overflow-y-hidden')
      .forEach((element) =>
        element.dispatchEvent(new Event('overflow-toggle'))
      )
  }

  selectDropdownPosition (): void {
    const dropdown = this.plugin.dropdown
    const inputRect = this.plugin.control_input.getBoundingClientRect()
    const spaceBelow = window.innerHeight - inputRect.bottom

    dropdown.style.top =
      spaceBelow > 260 ? '' : `-${dropdown.clientHeight + 10}px`
  }
}
