import { Controller } from '@hotwired/stimulus'

// This controller is used to reset the search form and filter checkboxes
export default class extends Controller {
  static targets = ['input', 'checkbox']

  declare filterApplied: boolean
  declare readonly inputTarget: HTMLInputElement
  declare readonly checkboxTargets: HTMLInputElement[]

  setFlag (): void {
    this.filterApplied = true
  }

  clearSearch (): void {
    this.inputTarget.value = ''
  }

  clearAll (): void {
    this.clearSearch()
    this.resetCheckboxes()

    const event = new CustomEvent('count-checkboxes:reset')
    document.dispatchEvent(event)

    // submit the form if a filter is applied, thus getting the results without the filter
    if (this.filterApplied) {
      const form = this.checkboxTargets[0].closest('form') as HTMLFormElement
      form.requestSubmit()
      this.filterApplied = false
    }
  }

  private resetCheckboxes (): void {
    this.checkboxTargets.forEach(checkbox => { checkbox.checked = false })
  }
}
